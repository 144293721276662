import { putRequest } from 'utils/http';
import { BACKEND_API } from 'global/environment';
import { serialize } from 'object-to-formdata';
import { ConfigureRequest, ConfigureResponse } from './types';

const baseUrl = `${BACKEND_API}/chargepoints`;

export async function putConfigureAPI(
  chargePointId: string,
  bodyData: ConfigureRequest | undefined
): Promise<ConfigureResponse> {
  const formData = serialize(bodyData, { noFilesWithArrayNotation: true });
  
  return putRequest<ConfigureResponse>(`${baseUrl}/${chargePointId}/configure`, formData);
}
