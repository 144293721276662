import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AccountInfo, InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from 'authConfig';

interface ErrorType {
  statusCode: string; 
  message: string;
}

function handleHttpError(error: AxiosError): ErrorType {
  const genericErrorMessage = 'Något blev fel. Försök igen.'; // Something Failed. Try again?
  const sessionExpiredErrorMessage = 'Sessionen har gått ut, logga in igen.'; //Session expired, please re-login

  if (error.response) {
    if (error.response.status === 401) {
      return {
        statusCode: error.response.status.toString(),
        message: sessionExpiredErrorMessage
      };
    }

    if (error.response.data) {
      return {
        statusCode: error.response.status.toString(),
        message: error.response.data.message || genericErrorMessage
      };
    }
  }

  return {
    statusCode: '500',
    message: genericErrorMessage,
  };
}

function makeHttpRequest<T>(apiCall: Function): Promise<T> {
  return new Promise(async (resolve, reject) => {
    try {
      const data: AxiosResponse = await apiCall();
      resolve(data.data);
    } catch (e) {
      const err = e as AxiosError;
      reject(handleHttpError(err));
    }
  });
}

export function getRequest<T>(path: string, options?: AxiosRequestConfig) {
  return makeHttpRequest<T>(() => axios.get(path, options));
}
export function postRequest<T>(path: string, data: any = {}, options?: AxiosRequestConfig) {
  return makeHttpRequest<T>(() => axios.post(path, data, options));
}
export function putRequest<T>(path: string, data: any = {}, options?: AxiosRequestConfig) {
  return makeHttpRequest<T>(() => axios.put(path, data, options));
}
export function deleteRequest<T>(path: string, options?: AxiosRequestConfig) {
  return makeHttpRequest<T>(() => axios.delete(path, options));
}

export function setToken(idToken?: string) {
  if (idToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${idToken}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
}

export async function useSetToken() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0] as AccountInfo
    }).then((tokenResponse) => {
      setToken(tokenResponse?.idToken);
    }).catch((err) => {
      if (err instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect(loginRequest);
      }
    });
  }
}
