import { useIsAuthenticated } from '@azure/msal-react';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const isAuthenticated = useIsAuthenticated();

  const routeComponent = (props: any) => (
    isAuthenticated
      ? React.createElement(Component, props)
      : <Redirect to={{ pathname: '/' }} />
  );
  return (
    <Route
      {...rest}
      render={routeComponent}
    />
  );
};
