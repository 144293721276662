import React from 'react';
import {BeatLoader} from "react-spinners";

import styles from './styles.module.scss';

const Loader = ({ loading, message, css, size } : any) => {

    if (size === undefined)
        size = 30;

    return loading ? (
        <div className={styles.loader}>
            <BeatLoader
                    css={css}
                    size={size}
                    color={"#000000"}
                    loading={loading}
                />
            <span className={styles.message}>
                {message}
            </span>
        </div>
    ) : null
};

export default Loader;
