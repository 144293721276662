import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';
import { SignOutButton } from '../SignOutButton';
import logo from '../../../assets/resources/logo.png';
import styles from './styles.module.scss';

const Header = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, accounts } = useMsal();
  const accountName = (accounts[0] as AccountInfo)?.name;

  return (
    <div className={styles.header}>
      <div>
        <img
          src={logo}
          alt="logo"
          className={styles.logo}
        />
      </div>
      <div className={isAuthenticated ? styles.navigation : styles.noNavigation}>
        <p className={styles.welcomeMessage}>{ isAuthenticated && accountName ? `Välkommen ${accountName}` : '' }</p>
        { inProgress === 'none' ? (
          <div className={styles.signOut}>
            { isAuthenticated ? <SignOutButton /> : '' }
          </div>
        ) 
          : '' }
      </div>
    </div>
  );
};

export default Header;
