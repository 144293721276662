import * as React from 'react'
import * as PropTypes from 'prop-types'
import FileIcon from './FileIcon'
import PDFIcon from './PDFIcon'
import FolderCloseIcon from './FolderCloseIcon'
import FolderOpenIcon from './FolderOpenIcon'

type Props = {
   icon:string;
}
const Icons = (props:any) => {
    switch (props.icon) {
        case 'PDF':
            return <PDFIcon />
        case 'File':
            return <FileIcon />
        case 'Text':
            return <FileIcon />
        case 'FolderOpen':
            return <FolderOpenIcon />
        case 'Folder':
            return <FolderCloseIcon />
        default: return <PDFIcon />
    }
}

Icons.propTypes = {
  icon: PropTypes.string.isRequired,
}

export default Icons
