import React, { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Button } from 'components/Button';
import { base64ToFile } from 'utils/Base64ToFile';
import { RETAKE_PHOTO, TAKE_PHOTO } from 'assets/constants';
import { compressImageFile } from 'utils/compressImageFile';
 
const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: 'user'
};
 
export const WebcamCapture = ({ setImageFile, imageFileName, className }: any) => {

  const webcamRef = useRef(null);
  const [image, setImage] = useState('');

  const capture = useCallback(
    async () => {
      const imageSrc = (webcamRef as any).current.getScreenshot();
      
      setImage(imageSrc);
      
      const imageFile = await base64ToFile(imageSrc, imageFileName);
      const compressedFile = await compressImageFile(imageFile);

      setImageFile(compressedFile);
    }, [webcamRef, imageFileName, setImageFile]
  );
 
  return (
    <div>
      <div className={className}>                     
        {image === '' ? (
          <Webcam
            audio={false}
            width={400}
            height={400}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
        ) : (
          <img
            src={image}
            alt=""
          />
        )}
      </div>

      <div className={className}>                 
        {image !== '' ? (
          <Button
            onClick={() => {
              setImage('');
              setImageFile(undefined);
            }}
            disabled={false}
          >
            {RETAKE_PHOTO}
          </Button>
        )
          : (
            <Button
              onClick={capture}
              disabled={false}
            >
              {TAKE_PHOTO}
            </Button>
          )}
      </div>
    </div>
  );
};
