export interface OrderResponse {
  orderId: string;
  orderName: string;
  orderDate: string;
  orderDescription: string;
  extendedOrderDescription: string;
  status: OrderStatus;
  installerId: string;
  installer: string;
  contactPerson: string;
  telephone: string;
  location: {
    locationIdentity: string;
    latitude: number;
    longitude: number;
    name: string;
    houseNumber: string;
    streetName: string;
    city: string;
    postalCode: string;
    country: string;
    pictureUrl: string;
  };
  customer: Customer;
  addonCosts: OrderAddonCost[];
}

export interface Customer {
  customerId: string;
  customerName: string;
  mailAddress: string;
  deliveryMail1: string;
  deliveryMail2: string;
  deliveryMail3: string;
  remark: string;
}

export enum OrderStatus {
  Pending = 0,
  InProgress = 1,
  Completed = 2,
  Error = 3,
  Cancelled = 4,
  Modified = 5
}

export interface OrderAddonCost {
  sku: string;
  description: string;
  price: number;
  quantity: number;
  amount: number;
}

export interface ErrorType {
  statusCode: string; 
  message: string;
}
