import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Container from 'components/layout/Container';
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';
import { Button } from 'components/Button';
import { AppContext } from 'store';
import { useCustomer } from 'services/customers/customer/hook';
import checkIcon from 'assets/icons/big_check.svg';
import * as Constants from '../../assets/constants';
import styles from './styles.module.scss';

interface LocationState {
  chargePointId: string;
  customerId: string;
  locationId: string;
  model: string;
  vendor: string;
  serialNumber: string;
  fuseSize: string;
}

const MessagePage = () => {
  const { setOrderId }: any = useContext(AppContext);
  const location = useLocation<LocationState>();
  const { chargePointId, customerId, model, vendor, serialNumber, fuseSize } = location?.state;

  const { customer } = useCustomer(customerId, () => {});

  const history = useHistory();

  const goBackToHomePage = () => {
    setOrderId('');
    history.push('/');
  };

  return (
    <div className={styles.homePage}>
      <Header />
      <Container>
        <div className={styles.messagePage}>
          <h1 className={styles.title}>{Constants.MESSAGE_TITLE}</h1>
          <h2 className={styles.title}>{Constants.MESSAGE_SUBTITLE}</h2>
          <div className={styles.info}>
            <p><strong>{Constants.CUSTOMER_NUMBER}</strong> {customerId}</p>
            <p><strong>{Constants.CUSTOMER_NAME}</strong> {customer?.customerName}</p>
            <p><strong>{Constants.CHARGER}</strong> {chargePointId}</p>
            <p><strong>{Constants.SERIAL_NUMBER_MESSAGE}</strong> {serialNumber}</p>
            <p><strong>{Constants.FUSE_SIZE_MESSAGE}</strong> {fuseSize}</p>
            <p><strong>{Constants.MODEL}</strong> {model}</p>
            <p><strong>{Constants.VENDOR}</strong> {vendor}</p>
          </div>
          <div className={styles.checkInfo}>
            <img
              src={checkIcon}
              alt="check-icon"
            />
            <h2 className={styles.checkText}>{Constants.MESSAGE}</h2>
          </div>
          
          <Button
            disabled={false}
            onClick={goBackToHomePage}
          >{Constants.GO_TO_HOME_PAGE}
          </Button>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default MessagePage;
