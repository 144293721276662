import React from 'react';
import Container from 'components/layout/Container';
import Header from 'components/layout/Header';
import { Button } from 'components/Button';
import Footer from 'components/layout/Footer';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';
import InstallationManualsPage from 'pages/InstallationManualsPage';
import * as Constants from '../../assets/constants';
import styles from './styles.module.scss';

const HomePage = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.log(e); // should show error instead
    });
  };

  return (
    <div className={styles.homePage}>
      <Header />
      <Container>

        <AuthenticatedTemplate>
          <InstallationManualsPage />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <div className={styles.login}>
            <h2 className={styles.orderLabel}>{Constants.LOGIN_TITLE}</h2>

            <div className={styles.button}>
              <Button
                disabled={false}
                onClick={() => handleLogin()}
              >{Constants.LOGIN}
              </Button>
            </div>
          </div>
        </UnauthenticatedTemplate>
      </Container>
      <Footer />
    </div>
  );
};

export default HomePage;
