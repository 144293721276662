/* eslint-disable max-len */
import React from 'react'

const FolderOpenIcon=()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
      xmlSpace="preserve"
    >
      <path d="M7 40q-1.15 0-2.075-.925Q4 38.15 4 37V11q0-1.15.925-2.075Q5.85 8 7 8h14.05l3 3H41q1.15 0 2.075.925Q44 12.85 44 14H22.75l-3-3H7v26l5.1-20H47l-5.35 20.7q-.3 1.2-1.1 1.75T38.5 40Zm3.15-3h28.6l4.2-17h-28.6Zm0 0 4.2-17-4.2 17ZM7 14v-3 3Z" />
    </svg>
  )
}

export default FolderOpenIcon