import { useMutation, ReactQueryMutationsConfig } from 'react-query';
import { OrderResponse } from '../order/types';
import { updateOrderAPI } from './api';
import { ErrorType, UpdateOrderRequest } from './types';

export function useUpdateOrder(
  orderId: string,
  bodyData: UpdateOrderRequest | undefined,
  { onSuccess }: ReactQueryMutationsConfig<OrderResponse, ErrorType>
) {
  const [updateOrder, { isLoading, error }] = useMutation<OrderResponse, ErrorType, UpdateOrderRequest>(
    () => updateOrderAPI(orderId, bodyData), 
    {
      onSuccess
    }
  );
  return {
    updateOrder,
    isLoading,
    error,
  };
}
