import { postRequest } from 'utils/http';
import { BACKEND_API } from 'global/environment';
import { OrderResponse } from '../order/types';
import { UpdateOrderRequest } from './types';

const url = `${BACKEND_API}/orders`;

export async function updateOrderAPI(
  orderId: string,
  bodyData: UpdateOrderRequest | undefined
): Promise<OrderResponse> {
  return postRequest<OrderResponse>(`${url}/${orderId}`, bodyData);
}
