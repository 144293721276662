import React, { useContext, useState } from 'react';
import { WebcamCapture } from 'components/WebcamCapture/WebcamCapture';
import { FileUpload } from 'components/FileUpload/FileUpload';
import { Button } from 'components/Button';
import { isDesktop, } from 'react-device-detect';
import { AppContext } from 'store';
import * as Constants from '../../../../assets/constants';
import styles from './styles.module.scss';

const UploadInstallationImages = ({
  nextStep,
  setLocationImage,
  setInstallationImages,
  fuseSize
}: any) => {
  const { order }: any = useContext(AppContext);
  const [locationFile, setLocationFile] = useState<File>();
  const [chargePointFile, setChargePointFile] = useState<File>();
  const [installationFile, setInstallationFile] = useState<File>();
  const [electricalCabinetFile, setElectricalCabinetFile] = useState<File>();
  const [additionalWorkFile, setAdditionalWorkFile] = useState<File>();
  const [externalMeterFile, setExternalMeterFile] = useState<File>();

  const uploadLocationImages = async () => {
    setLocationImage(locationFile);

    const installationImageFiles: (File | undefined)[] = [
      chargePointFile, 
      installationFile, 
      electricalCabinetFile, 
      additionalWorkFile, 
      externalMeterFile];
    setInstallationImages(installationImageFiles);

    nextStep();
  };

  return (
    <div className={styles.uploadLocationImages}>
      <div className={styles.inputSection}>
        <label>{Constants.LOCATION_IMAGE}</label>
        { isDesktop 
          ? (
            <WebcamCapture
              setImageFile={(file: File) => { setLocationFile(file); }}
              imageFileName="LocationImage.jpeg"
              className={styles.webcam}
            />
          )
          : '' }
        <FileUpload
          setFile={(file: File) => { setLocationFile(file); }} 
          className={styles.fileUpload}
        />
      </div>

      <div className={styles.inputSection}>
        <label>{Constants.CHARGE_POINT_IMAGE}</label>
        { isDesktop 
          ? (
            <WebcamCapture
              setImageFile={(file: File) => { setChargePointFile(file); }}
              imageFileName="ChargePointImage.jpeg"
              className={styles.webcam}
            />
          )
          : '' }
        <FileUpload
          setFile={(file: File) => { setChargePointFile(file); }} 
          className={styles.fileUpload}
        />
      </div>

      <div className={styles.inputSection}>
        <label>{Constants.INSTALLATION_IMAGE}</label>
        { isDesktop 
          ? (
            <WebcamCapture
              setImageFile={(file: File) => { setInstallationFile(file); }}
              imageFileName="InstallationImage.jpeg"
              className={styles.webcam}
            />
          )
          : '' }
        <FileUpload
          setFile={(file: File) => { setInstallationFile(file); }} 
          className={styles.fileUpload}
        />
      </div>

      <div className={styles.inputSection}>
        <label>{Constants.ELECTRICAL_CABINET_IMAGE}</label>
        { isDesktop 
          ? (
            <WebcamCapture
              setImageFile={(file: File) => { setElectricalCabinetFile(file); }}
              imageFileName="ElectricalCabinetImage.jpeg"
              className={styles.webcam}
            />
          )
          : '' }
        <FileUpload
          setFile={(file: File) => { setElectricalCabinetFile(file); }} 
          className={styles.fileUpload}
        />
      </div>

      <div className={styles.inputSection}>
        <label>{Constants.ADDITIONAL_WORK_IMAGE}</label>
        { isDesktop 
          ? (
            <WebcamCapture
              setImageFile={(file: File) => { setAdditionalWorkFile(file); }}
              imageFileName="AdditionalWorkImage.jpeg"
              className={styles.webcam}
            />
          )
          : '' }
        <FileUpload
          setFile={(file: File) => { setAdditionalWorkFile(file); }} 
          className={styles.fileUpload}
        />
      </div>
        
      <div className={styles.inputSection}>
        <label>{Constants.EXTERNAL_METER_IMAGE}</label>
        { isDesktop 
          ? (
            <WebcamCapture
              setImageFile={(file: File) => { setExternalMeterFile(file); }} 
              imageFileName="ExternalMeterImage.jpeg"
              className={styles.webcam}
            />
          )
          : '' }
        <FileUpload
          setFile={(file: File) => { setExternalMeterFile(file); }} 
          className={styles.fileUpload}
        />
      </div>

      <div className={styles.button}>
        <Button
          disabled={!!(locationFile === undefined
            || chargePointFile === undefined
            || installationFile === undefined
            || electricalCabinetFile === undefined
            || (order?.addonCosts.length && additionalWorkFile === undefined)
            || (fuseSize !== Constants.NO_LOAD_BALANCING && externalMeterFile === undefined)
          )}
          onClick={uploadLocationImages}
        >{Constants.CONFIRM}
        </Button>
      </div>
    </div>
  );
};

export default UploadInstallationImages;
