import React from 'react';
import Select from 'react-select';
import styles from './styles.module.scss';
import { SelectInputProps } from './types';

export const SelectInput = ({
  value,
  data,
  handleChange,
  placeholder,
  disabled
}: SelectInputProps) => {
  const changeValue = (selectedOption: { value: any }) => {
    const value = selectedOption ? selectedOption.value : null;

    handleChange(value);
  };

  const getValue = value && data?.filter(o => o.value === value);
    
  const selectInput = () => (
    <Select
      value={getValue}
      options={data}
      onChange={changeValue}
      placeholder={placeholder}
      isDisabled={disabled}
      className={styles.selectInput}
    />
  );
  
  return (
    selectInput()
  );
};
