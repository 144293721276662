import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/Button';
import { AppContext } from 'store';
import { MESSAGE_PAGE } from 'global/routes';
import { useConfigure } from 'services/chargepoints/configure/hook';
import { CheckboxInput } from 'components/CheckboxInput';
import { ConfigureRequest, ConfigureResponse } from 'services/chargepoints/configure/types';
import Loader from 'react-spinners/ClipLoader';
import { useRollbar } from '@rollbar/react';
import * as Constants from '../../../../assets/constants';
import styles from './styles.module.scss';

const ConfigureCP = ({ serialNumber, orderId, fuseSize, chargePointId, locationImage, installationImages }: any) => {
  const [checkboxInstallation, setCheckboxInstallation] = useState(false);
  const [checkboxCustomer, setCheckboxCustomer] = useState(false);
  const [checkboxChargingBox, setCheckboxChargingBox] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [bodyData, setBodyData] = useState<ConfigureRequest>();
  const [configurationFailed, setConfigurationFailed] = useState(false);

  const rollbar = useRollbar();

  const { position }: any = useContext(AppContext);

  const history = useHistory();

  const { configure, error } = useConfigure(
    chargePointId,
    bodyData,
    { onSuccess: (res: ConfigureResponse) => (res.chargePointId
      ? history.push(MESSAGE_PAGE, 
        { 
          chargePointId: res.chargePointId,
          customerId: res.customerId,
          locationId: res.locationId,
          model: res.model, 
          vendor: res.vendor,
          serialNumber: serialNumber,
          fuseSize: fuseSize,
          locationImage: locationImage,
          installationImages: installationImages
        })
      : setConfigurationFailed(true))
    }
  );

  const configureCharger = async () => {
    setDisabled(true);

    const body: ConfigureRequest = {
      orderId: orderId || '',
      latitude: position.lat || '',
      longitude: position.long || '',
      fuseSize: fuseSize === Constants.NO_LOAD_BALANCING ? '' : fuseSize,
      locationImage: locationImage,
      installationImages: installationImages
    };

    setBodyData(body);

    const result = await configure(body);

    if (!result || !result?.chargePointId) {
      setDisabled(false);
    }

    rollbar.info('configureCharger', { configureChargerRequest: body, configureChargerResponse: result });
  };

  const handleErrorMessage = () => {
    if (error?.statusCode === '401') {
      return error.message;
    } 
    if (error?.statusCode === '400') {
      return Constants.INCORRECT_REQUEST_RELOAD_PAGE_AND_TRY_AGAIN;
    } 
    if (error?.statusCode === '404') {
      return Constants.ALREADY_COMMISSIONED;
    } 
    if (error?.statusCode === '500') {
      return Constants.GENERIC_ERROR_MESSAGE;
    }
    if (configurationFailed) {
      return Constants.IMAGES_TOO_LARGE;
    }
    return '';
  };

  return (
    <div className={styles.configureCP}>
      <div className={styles.inputSection}>
        <label>{Constants.CHARGE_POINT_ID}</label>
        <p>{serialNumber}</p>
        <label>{Constants.ORDER_NUMBER}</label>
        <p>{orderId}</p>
        <label>{Constants.FUSE_SIZE}</label>
        <p>{fuseSize}</p>
      </div>

      <div>
        <CheckboxInput
          checked={checkboxInstallation}
          labelText={Constants.INSTALLATION_IS_ACCORDING_MANUALS}
          onChange={(e: any) => setCheckboxInstallation(e)}
        />
        <CheckboxInput
          checked={checkboxCustomer}
          labelText={Constants.CUSTOMER_INFORMED}
          onChange={(e: any) => setCheckboxCustomer(e)}
        />
        <CheckboxInput
          checked={checkboxChargingBox}
          labelText={Constants.CHARGING_BOX_IS_WORKING}
          onChange={(e: any) => setCheckboxChargingBox(e)}
        />
      </div>
      
      <p className={styles.errorMessage}>{handleErrorMessage()}</p>
     
      <div className={styles.button}>
        <Button
          disabled={!(checkboxInstallation && checkboxCustomer && checkboxChargingBox) || disabled}
          onClick={configureCharger}
        >
          <Loader
            size={20}
            color="#fff"
            loading={disabled}
            css="margin-right: 10px;"
          />
          {Constants.CONFIRM}
        </Button>
      </div>
    </div>
  );
};

export default ConfigureCP;
