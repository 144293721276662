import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from 'components/Button';

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logout().catch(e => {
      console.log(e); // should show error instead
    });
  };

  return (
    <Button
      disabled={false}
      onClick={() => handleLogout()}
    >Logga ut
    </Button>
  );
};
