import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ReactQueryCacheProvider } from 'react-query';
import { Provider } from '@rollbar/react';
import { rollbarConfig } from 'rollbarConfig';
import { useSetToken } from 'utils/http';
import { queryCache, AppContext } from 'store';
import { PrivateRoute } from 'global/privateRoute';
import {
  HOME_PAGE,
  INSTALLATION_MANUALS_PAGE,
  GET_ORDER,
  HOME_CHECK_FLOW,
  ORDER_VERIFICATION_FLOW,
  MESSAGE_PAGE
} from 'global/routes';
import { Customer, OrderResponse } from 'services/orders/order/types';
import HomePage from 'pages/HomePage';
import InstallationManualsPage from 'pages/InstallationManualsPage';
import GetOrderPage from 'pages/GetOrderPage';
import HomeCheckFlow from 'pages/HomeCheckFlow';
import OrderVerificationFlow from 'pages/OrderVerificationFlow';
import MessagePage from 'pages/MessagePage';

const App = () => {
  const [canAccess, setCanAccess] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [order, setOrder] = useState<OrderResponse>();
  const [customerId, setCustomerId] = useState('');
  const [position, setPosition] = useState({ lat: 0, long: 0 });
  const [customer, setCustomer] = useState<Customer>();
  
  useSetToken();

  return (
    <Provider config={rollbarConfig}>
      <Router>
        <ReactQueryCacheProvider queryCache={queryCache}>
          <AppContext.Provider
            value={{
              canAccess,
              setCanAccess, 
              orderId,
              setOrderId,
              order,
              setOrder,
              customerId,
              setCustomerId,
              position,
              setPosition,
              customer,
              setCustomer }}
          >
            <Switch>

              <Route
                exact
                path={HOME_PAGE}
                component={HomePage}
              />
              <PrivateRoute
                path={GET_ORDER}
                component={GetOrderPage}
              /> 
              <PrivateRoute
                path={INSTALLATION_MANUALS_PAGE}
                component={InstallationManualsPage}
              /> 
              <PrivateRoute
                path={HOME_CHECK_FLOW}
                component={HomeCheckFlow}
              />                       
              <PrivateRoute
                path={ORDER_VERIFICATION_FLOW}
                component={OrderVerificationFlow}
              />
              <PrivateRoute
                path={MESSAGE_PAGE}
                component={MessagePage}
              />
              <Route path="*">
                <Redirect to={HOME_PAGE} />
              </Route>

            </Switch>
          </AppContext.Provider>
        </ReactQueryCacheProvider>
      </Router>
    </Provider>
  );
};

export default App;
