/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import * as PropTypes from 'prop-types'
import { extensionMapping } from '../constants'
import Icons from '../Icons/index'
import styles from './TableFile.module.scss'
import { OPEN } from 'assets/constants'

const TableFile=(props:any)=> {
  const getName = () => {
    let name = props.fileKey
    const slashIndex = name.lastIndexOf('/')
    if (slashIndex !== -1) {
      name = name.substr(slashIndex + 1)
    }
    return name
  }
  const getDocumentUrl = () => {
    let doc = props.documentUrl
    return doc
  }

  const getExtension = () => {
    const blobs = props.fileKey.split('.')
    return blobs[blobs.length - 1].toLowerCase().trim()
  }

  const getFileType = () => extensionMapping[getExtension()] || 'File'

  const handleFileClick = (event:any) => {
    event.preventDefault()
    props.handleFileClick(props.fileKey)
  }

  const icon = getFileType()

  return (
    <tr className={styles.tableRow}>
      <td className={styles.name}>
        <div style={{ paddingLeft: `${props.depth * 16}px` }}>
          <a
            href={getDocumentUrl()} 
            target="_blank"
            rel="noopener noreferrer"
            className={styles.nameLink}
          >
            <Icons icon={icon} />
            <span>{getName()}</span>
          </a>
        </div>
      </td>
    
      <td className={styles.openFile}>
      <a
        href={getDocumentUrl()} 
        target="_blank"
        rel="noopener noreferrer"
        className={styles.fileUrl}
      > {OPEN}</a>
       
      </td>
    </tr>
  )
}

TableFile.propTypes = {
  fileKey: PropTypes.string.isRequired,
  documentUrl: PropTypes.string.isRequired,
  depth: PropTypes.number.isRequired,
  handleFileClick: PropTypes.func.isRequired,
}
export default TableFile
