import React from 'react';
import styles from './styles.module.scss';
import { TextInputProps } from './types';

export const TextInput = ({
  type = 'text',
  pattern = '[a-zA-Z0-9]+',
  placeholder,
  defaultValue = '',
  disabled = false,
  onChange }: TextInputProps) => (
    <input
      type={type}
      pattern={pattern}
      placeholder={placeholder}
      className={styles.orderInput}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={e => onChange && onChange(e.target)}
    />
);
