import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import { AppContext } from 'store';
import { useHistory } from 'react-router-dom';
import { useUpdateOrder } from 'services/orders/updateOrder/hook';
import { ORDER_VERIFICATION_FLOW } from 'global/routes';
import { UpdateOrderRequest } from 'services/orders/updateOrder/types';
import Loader from 'react-spinners/ClipLoader';
import { useRollbar } from '@rollbar/react';
import * as Constants from '../../../../assets/constants';
import styles from './styles.module.scss';

const VerifyCustomerEmail = ({ filteredAddonCosts }: any) => {
  const { customer, orderId, setOrder }: any = useContext(AppContext);
  const [customerEmail, setCustomerEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [bodyData, setBodyData] = useState<UpdateOrderRequest>();
  const [disabled, setDisabled] = useState(false);
  const rollbar = useRollbar();

  useEffect(() => {
    if (customer?.mailAddress) {
      setCustomerEmail(customer.mailAddress);
    }
  }, [customer]);

  const handleEmailInput = (target: any) => {
    if (target.value === '') {
      setErrorMessage(Constants.CUSTOMER_EMAIL_NOT_EMPTY);
    } else if (!target.validity.valid) {
      setErrorMessage(Constants.CUSTOMER_EMAIL_INVALID_FORMAT);
    } else {
      setErrorMessage('');
    }
    
    setCustomerEmail(target.value.toLowerCase());
  };

  const history = useHistory();

  const { updateOrder, error } = useUpdateOrder(
    orderId,
    bodyData,
    {
      onSuccess: (res) => history.push(ORDER_VERIFICATION_FLOW,
        {
          addonCosts: res.addonCosts
        })
    }
  );

  const verifyCustomerEmail = async () => {
    setDisabled(true);

    const body: UpdateOrderRequest = {
      addonCosts: filteredAddonCosts || [],
      customerEmail: customerEmail || customer.mailAddress
    };

    setBodyData(body);
    
    const orderResponse = await updateOrder(body);
    setOrder(orderResponse);

    if (!orderResponse) {
      setDisabled(false);
    }

    rollbar.info('verifyCustomerEmail', { verifyCustomerEmailRequest: body, verifyCustomerEmailResponse: orderResponse });
  };

  const handleErrorMessage = () => {
    if (errorMessage) {
      return errorMessage;
    }
    if (error?.statusCode === '401') {
      return error.message;
    } 
    if (error?.statusCode === '400') {
      return Constants.INCORRECT_REQUEST_RETURN_AND_TRY_AGAIN;
    } 
    if (error?.statusCode === '404') {
      return Constants.ORDER_NUMBER_NOT_FOUND_ERROR;
    } 
    if (error?.statusCode === '500') {
      return Constants.GENERIC_ERROR_MESSAGE;
    }
    return '';
  };

  return (
    <div className={styles.verifyCustomerEmail}>
      <div className={styles.inputSection}>
        <label>{Constants.VERIFY_CUSTOMER_EMAIL}</label>

        <TextInput
          placeholder={Constants.CUSTOMER_EMAIL}
          defaultValue={customer.mailAddress ?? ''}
          onChange={(target: any) => handleEmailInput(target)}
          pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        />

        <p className={styles.errorMessage}>{handleErrorMessage()}</p>

      </div>

      <div className={styles.button}>
        <Button
          disabled={!!(errorMessage || customerEmail === '' || disabled)}
          onClick={verifyCustomerEmail}
        >
          <Loader
            size={20}
            color="#fff"
            loading={disabled}
            css="margin-right: 10px;"
          />
          {Constants.CONFIRM}
        </Button>
      </div>
    </div>
  );
};

export default VerifyCustomerEmail;
