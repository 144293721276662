import React from 'react';
import { CheckboxInputProps } from './types';
import styles from './styles.module.scss';

export const CheckboxInput = ({ onChange, checked, labelText, disabled }: CheckboxInputProps) => (
  <div className={styles.checkboxInput}>
    <label>
      <input
        type="checkbox"
        checked={checked}
        onChange={e => onChange(e.currentTarget.checked)}
        disabled={disabled}
      />
      <span>{labelText}</span>
    </label>
  </div>    
);
