import * as React from 'react'
import * as styles from './TableHeader.module.scss'

const TableHeader=()=> {
  return (
    <thead className={styles.tableHeader}>
      <tr>
        <th>Titel</th>
        <th className={styles.dokument}>Dokument</th>
      </tr>
    </thead>
  )
}

export default TableHeader
