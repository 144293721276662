// Login screen
export const LOGIN_TITLE = 'Välkommen!';
export const LOGIN = 'Logga in';

// Installation manuals page
export const INSTALLATION_MANUALS_TITLE = 'Titel';
export const INSTALLATION_MANUALS_DOCUMENT = 'Dokument';
export const INSTALLATION_MANUALS = 'Installation Manuals';
export const OPEN = 'Öppna';

// Home page 
export const ORDER_NUMBER_LABEL = 'Fyll i Mer\'s ordernummer här:';
export const ORDER_NUMBER = 'Ordernummer';
export const CHECK_ORDER = 'Klicka här';
export const ORDER_NUMBER_INFO = '8 tecken';
export const ORDER_NUMBER_NOT_FOUND_ERROR = `Ordernumret hittades inte. Säkerställ att du har fyllt i rätt ordernummer. 
Om problemet kvarstår, kontakta Mer’s support, 070-003 39 70.`;
export const ORDER_NUMBER_NOT_EMPTY = 'Ordernummer får inte lov att vara tomt!';
export const ORDER_NUMBER_ONLY_ALPHA_NUMBERIC = 'Ordernumret måste bestå av endast alfanumeriska tecken!';
export const LOADING = 'Laddar';
export const ORDER_NOT_ACTIVE = 'Kan inte hitta den angivna ordern';
export const CUSTOMER_NOT_FOUND = 'Kan inte hitta den angivna kunden';

// Stepper
export const VERIFY_CUSTOMER = 'Bekräfta kunduppgifter';
export const VERIFY_SERIAL_NUMBER = 'Bekräfta serienummer';
export const ENTER_SIZE_OF_MAIN_FUSE = 'Ange storlek på huvudsäkring';
export const UPLOAD_INSTALLATION_IMAGES = 'Ladda upp bilder';
export const CONFIGURE_CP = 'Godkänn';
export const RETURN_BACK = 'Tillbaka';
export const CONFIRM = 'Bekräfta';

// Home Check Stepper
export const SELECT_ADDON_COSTS = 'Addera tillkommande kostnader';
export const VERIFY_ADDON_COSTS = 'Bekräfta tillkommande kostnader';
export const VERIFY_CUSTOMER_EMAIL = 'Ange kunds e-postadress för orderbekräftelse';

// Verify Addon Costs
export const ADDON_COST_DESCRIPTION = 'Beskrivning';
export const ADDON_COST_PRICE_INCL_VAT = 'Pris inkl. moms';
export const ADDON_COST_PRICE_EXCL_VAT = 'Pris ex. moms';
export const ADDON_COST_QUANTITY = 'Antal';
export const ADDON_COST_AMOUNT = 'Summa inkl. moms';
export const ADDON_COSTS_TOTAL_AMOUNT = 'Totalt';
export const ADDON_COSTS_NOT_SELECTED = 'Du har inte valt några tillkommande kostnader.';

// Verify Customer Email
export const CUSTOMER_EMAIL = 'Kundens e-postadress';
export const CUSTOMER_EMAIL_NOT_EMPTY = 'Kundens e-postadress får inte lov att vara tomt.';
export const CUSTOMER_EMAIL_INVALID_FORMAT = 'Ogiltigt e-postformat';
export const INCORRECT_REQUEST_RETURN_AND_TRY_AGAIN = 'Felaktig begäran. Gå tillbaka till startsidan och försök igen.';

// Order info
export const ORDER_ID = 'Ordernummer:';
export const CUSTOMER_NAME = 'Kundnamn:';
export const CUSTOMER_NUMBER = 'Kundnummer:';
export const CUSTOMER_ADRESS = 'Installationsadress:';
export const POSTAL_CODE = 'Postnummer:';
export const CITY = 'Ort:';
export const COUNTRY = 'Land:';
export const LAT = 'Latitud:';
export const LONG = 'Longitud:';
export const FIND_LOCATION = 'Hämtar plats...';

// Verify charge point
export const VERIFY_CHARGE_POINT_LABEL = 'Fyll i laddboxens serienummer';
export const SERIAL_NUMBER = 'Serienummer';
export const SERIAL_NUMBER_NOT_EMPTY = 'Serienummer får inte lov att vara tomt!';
export const VERIFY_CHARGE_POINT_NOT_FOUND_ERROR = `Serienumret hittades inte. 
Säkerställ att du har fyllt i rätt serienummer och att laddboxen har kommunikation. 
Om problemet kvarstår, kontakta Mer’s support, 070-003 39 70.`;

// Set fuse size
export const FUSE_SIZE = 'Säkringsstorlek';
export const SELECT_FUSE_SIZE = 'Välj säkringsstorlek';
export const NO_LOAD_BALANCING = 'No Load Balancing';

// Upload images of installation
export const TAKE_PHOTO = 'Ta en bild';
export const RETAKE_PHOTO = 'Ta bilder igen';
export const UPLOAD_FILE = 'Ladda upp';
export const LOCATION_IMAGE = 'Ladda upp bild på laddbox/placering';
export const CHARGE_POINT_IMAGE = 'Ladda upp bild på laddbox/öppen kåpa';
export const INSTALLATION_IMAGE = 'Ladda upp bild på installation';
export const ELECTRICAL_CABINET_IMAGE = 'Ladda upp bild på elskåp/öppen central';
export const ADDITIONAL_WORK_IMAGE = 'Ladda upp bild på tillkommande arbete (obligatorisk vid tillkommande)';
export const EXTERNAL_METER_IMAGE = 'Ladda upp bild på extern elmätare/lastbalansering (obligatorisk vid lastbalansering)';

// Configure charge point
export const CHARGE_POINT_ID = 'Serienummer';
export const ALREADY_COMMISSIONED = 'Kan inte gå vidare. Laddpunkten är redan driftsatt sedan tidigare';
export const INCORRECT_REQUEST_RELOAD_PAGE_AND_TRY_AGAIN = 'Felaktig begäran. Ladda om sidan och försök igen.';
export const INSTALLATION_IS_ACCORDING_MANUALS = 'Bekräftar att installation är utförd enligt installationsmanual';
export const CUSTOMER_INFORMED = 'Bekräftar kund är informerad om tillkommande kostnader';
export const CHARGING_BOX_IS_WORKING = 'Bekräftar att laddbox är fungerande vid driftsättning';
export const IMAGES_TOO_LARGE = `Laddpunkten kunde inte konfigureras, eftersom storleken 
på bilderna är för stor. Försök att ladda upp olika bilder.`;

// Message
export const MESSAGE_TITLE = 'Nu är du nästan klar!';
export const MESSAGE_SUBTITLE = 'Ta en skärmdump på informationen och spara i din lokala portal.';
export const CHARGER = 'Laddare:';
export const SERIAL_NUMBER_MESSAGE = 'Serienummer:';
export const FUSE_SIZE_MESSAGE = 'Säkringsstorlek:';
export const MODEL = 'Model:';
export const VENDOR = 'Vendor:';
export const MESSAGE = 'Bra jobbat, nu är installationen bekräftad mot Mer’s system.';
export const GO_TO_HOME_PAGE = 'Avsluta';

// Generic error message
export const GENERIC_ERROR_MESSAGE = 'Något blev fel. Försök igen.'; //Something went wrong. Try again.
