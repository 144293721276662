/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react'
import * as PropTypes from 'prop-types'
import * as styles from './TableFolder.module.scss'
import Icons from '../Icons'

const TableFolder=(props:any)=> {
  const getName = () => {
    if (props.name) {
      return props.name
    }
    const folders = props.fileKey.split('/')
    return folders[folders.length - 2]
  }

  const toggleFolder = () => {
    props.toggleFolder(props.fileKey)
  }

  const handleFolderClick = (event:any) => {
    event.stopPropagation()
    toggleFolder()
  }

  const handleFolderDoubleClick = (event:any) => {
    event.stopPropagation()
    toggleFolder()
  }

  const icon = props.isOpen ? 'FolderOpen' : 'Folder'

  const folder = (
    <tr
      onClick={handleFolderClick}
      onDoubleClick={handleFolderDoubleClick}
      className={styles.tableRow}
    >
      <td className="name">
        <div style={{ paddingLeft: `${props.depth * 16}px` }}>

          <div className={styles.link}>
            <Icons icon={icon} />
            <span>{getName()}</span>
          </div>

        </div>
      </td>
    </tr>
  )

  return folder
}

TableFolder.propTypes = {
  fileKey: PropTypes.string.isRequired,
  depth: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleFolder: PropTypes.func,
}

export default TableFolder
