import { useQuery } from 'react-query';
import { getOrderAPI } from './api';
import { OrderResponse, ErrorType } from './types';

const USER_QUERY_KEY = 'order';

export const useOrder = (orderId: string, onSuccess: any) => {
  const { data, isLoading, error, refetch, status } = useQuery<OrderResponse, ErrorType>(
    [USER_QUERY_KEY, orderId],
    () => getOrderAPI(orderId),
    {
      enabled: false,
      onSuccess,
      cacheTime: 0,
    },
  );

  return {
    getOrder: refetch,
    order: data,
    isLoading,
    error,
    status,
  };
};
