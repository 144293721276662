import { Button } from 'components/Button';
import React, { useEffect, useState } from 'react';
import { AddonCost } from 'services/addonCosts/types';
import { useAddonCosts } from 'services/addonCosts/hook';
import ReactTable from 'react-table-6';
import Loader from 'react-spinners/BeatLoader';
import { useRollbar } from '@rollbar/react';
import * as Constants from '../../../../assets/constants';
import 'react-table-6/react-table.css';
import styles from './styles.module.scss';

const SelectAddonCosts = ({ nextStep, setSelectedAddonCosts, selectedAddonCosts, setFilteredAddonCosts }: any) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);

  const rollbar = useRollbar();

  const { getAddonCosts } = useAddonCosts((addonCosts: AddonCost[]) => {
    setData(addonCosts);
    
    rollbar.info('getAddonCosts');
  });

  useEffect(() => {
    const fetchData = async () => {
      await getAddonCosts();
  
      setLoading(false);
    };

    if (selectedAddonCosts?.length > 0) {
      setLoading(false);
      setData(selectedAddonCosts);
    } else {
      fetchData();      
    }    
  }, [getAddonCosts, selectedAddonCosts]);

  const handleInputChange = (event: any, cellInfo: any) => {
    const tmp = [...data];    
    tmp[cellInfo.index][cellInfo.column.id] = event.target.value;

    const calculatedAmount = tmp[cellInfo.index].priceInclVAT * +event.target.value;
    tmp[cellInfo.index].amount = Math.round(calculatedAmount * 100) / 100;

    setData(tmp);
    setSelectedAddonCosts(tmp);
  };

  const renderEditable = (cellInfo: { index: number; column: { id: string }}) => {
    const cellValue = data[cellInfo.index][cellInfo.column.id];

    return (
      <input
        placeholder="0.0"
        type="number"
        step="0.1"
        min="0"
        onChange={(event) => handleInputChange(event, cellInfo)}
        value={cellValue}
        className={styles.inputStyle}
      />
    );
  };

  const columns = [
    { accessor: 'description', Header: Constants.ADDON_COST_DESCRIPTION, className: styles.columnStyle, width: 500 },
    { accessor: 'quantity',
      Header: Constants.ADDON_COST_QUANTITY,
      className: styles.columnStyle,
      width: 100,
      Cell: renderEditable 
    },
    { accessor: 'priceExclVAT', Header: Constants.ADDON_COST_PRICE_EXCL_VAT, className: styles.columnStyle, width: 150 },
    { accessor: 'priceInclVAT', Header: Constants.ADDON_COST_PRICE_INCL_VAT, className: styles.columnStyle, width: 150 },
    { accessor: 'amount', Header: Constants.ADDON_COST_AMOUNT, className: styles.columnStyle, width: 200 }
  ];

  const filterAddonCosts = () => {
    const selectedAddonCosts = data.filter(x => x.quantity > 0);
    
    setFilteredAddonCosts(selectedAddonCosts);
    nextStep();
  };
  
  const renderLoader = () => (
    <div className={styles.loader}> 
      <Loader
        color="#000000"
        loading={loading}
        size={20}
        margin={10}
      />
    </div>
  );

  return (
    <div className={styles.selectAddonCosts}>
        
      <ReactTable
        data={data}
        columns={columns}
        showPagination={false}
        pageSize={data?.length}
        className="-striped -highlight"
        loading={loading}
        LoadingComponent={renderLoader}
        getNoDataProps={() => ({ style: { display: 'none' } })}
      />

      <div className={styles.button}>
        <Button
          disabled={loading}
          onClick={filterAddonCosts}
        >{Constants.CONFIRM}
        </Button>
      </div>
        
    </div>
  );
};

export default SelectAddonCosts;
