import Compress from 'compress.js';
import { base64ToFile } from 'utils/Base64ToFile';

export async function compressImageFile(file: File): Promise<File> {
  const compress = new Compress();

  const resizedImage = await compress.compress([file], {
    size: 2,
    quality: 1,
    maxWidth: 1920,
    maxHeight: 1920,
    resize: false
  });

  const img = resizedImage[0];
  const base64str = `data:${img.ext};base64,${img.data}`;
  const compressedFile = await base64ToFile(base64str, file.name, img.ext);
  
  return compressedFile;
}  
