import {useState, useEffect} from 'react'

export const usePosition = () => {
    const [position, setPosition] = useState({latitude: 0, longitude: 0});
    const [errorPosition, setErrorPosition] = useState('');
    
    const onChange = ({coords}: any) => {
      setPosition({
        latitude: coords.latitude,
        longitude: coords.longitude,
      });
    };
    const onError = (error:any) => {
      if(error.code === 1){
        setErrorPosition('Platstjänst är avslagen, kan inte hämta koordinater'); // Your location is disabled. We can\'t get correct latitude and longitude!
      }else {
        setErrorPosition(error.message);
      }
    };

    useEffect(() => {
      const geo = navigator.geolocation;
      if (!geo) {
        setErrorPosition('Platstjänst stöds inte'); // Geolocation is not supported
        return;
      }
      let watcher = geo.watchPosition(onChange, onError);
      return () => geo.clearWatch(watcher);
    }, []);
    return {...position, errorPosition};
}