import { LogLevel, Configuration } from '@azure/msal-browser';
import { AUTH_AZURE_B2C_AUTHORITY, AUTH_AZURE_B2C_CLIENT_ID, AUTH_AZURE_B2C_REDIRECT_URI } from 'global/environment';

export const msalConfig: Configuration = {
  auth: {
    clientId: AUTH_AZURE_B2C_CLIENT_ID!,
    authority: AUTH_AZURE_B2C_AUTHORITY,
    knownAuthorities: [AUTH_AZURE_B2C_AUTHORITY!],
    redirectUri: AUTH_AZURE_B2C_REDIRECT_URI,
    postLogoutRedirectUri: AUTH_AZURE_B2C_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
        }
      }
    }
  }
};

export const loginRequest = {
  scopes: [AUTH_AZURE_B2C_CLIENT_ID!, 'openid']
};
