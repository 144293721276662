import React, { useState } from 'react';
import Steps from 'rc-steps';
import 'rc-steps/assets/index.css';
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';
import Container from 'components/layout/Container';
import { AddonCost } from 'services/addonCosts/types';
import { useHistory } from 'react-router-dom';
import LeftArrow from '../../assets/icons/left-arrow.png';
import * as Constants from '../../assets/constants';
import SelectAddonCosts from './Components/SelectAddonCosts';
import VerifyAddonCosts from './Components/VerifyAddonCosts';
import VerifyCustomerEmail from './Components/VerifyCustomerEmail';
import styles from './styles.module.scss';

const HomeCheckFlow = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedAddonCosts, setSelectedAddonCosts] = useState<AddonCost[]>([]);
  const [filteredAddonCosts, setFilteredAddonCosts] = useState<AddonCost[]>([]);

  const nextStep = () => {
    let s = currentStep + 1;

    if (s === 3) {
      s = 0;
    }

    setCurrentStep(s);
  };
  
  const history = useHistory();

  const goBack = () => {
    if (currentStep === 0) {
      history.push('/order');
    }
    
    setCurrentStep(currentStep - 1);
  };

  const getFinishIcon = () => (
    <svg 
      width="1em"
      height="1em"
      viewBox="0 -46 417.81333 417" 
      fill="currentColor"
      className={styles.finishIcon}
    >
      <path 
        d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 
            6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 
            0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 
            75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 
            15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"
      />
    </svg>
  );
  
  const icons = {
    finish: getFinishIcon(),
    error: null,
  };

  return (
    <div className={styles.homeCheckFlow}>
      <Header />
      <Container>
        <div
          className={styles.subNav}
          onClick={goBack}
        >
          <img
            src={LeftArrow}
            className={styles.leftArrowIcon}
            alt="leftArrow"
          />
          <div className={styles.goBackNav}>
            {Constants.RETURN_BACK}
          </div>
        </div>

        <Steps
          initial={0}
          current={currentStep}
          icons={icons}
        >
          <Steps.Step title={Constants.SELECT_ADDON_COSTS} />
          <Steps.Step title={Constants.VERIFY_ADDON_COSTS} />
          <Steps.Step title={Constants.VERIFY_CUSTOMER_EMAIL} />
        </Steps>

        <div className={styles.content}>

          {currentStep === 0 && (
          <SelectAddonCosts
            nextStep={nextStep}
            setSelectedAddonCosts={setSelectedAddonCosts}
            selectedAddonCosts={selectedAddonCosts}
            setFilteredAddonCosts={setFilteredAddonCosts}
          />
          )}

          {currentStep === 1 && (
          <VerifyAddonCosts
            nextStep={nextStep}
            filteredAddonCosts={filteredAddonCosts}
          />
          )}

          {currentStep === 2 && (
          <VerifyCustomerEmail
            filteredAddonCosts={filteredAddonCosts}
          />
          )}

        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default HomeCheckFlow;
