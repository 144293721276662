import { APP_VERSION } from 'global/environment';
import React from 'react';

import styles from './styles.module.scss';

const Footer = () => (
  <div className={styles.footer}>
    <p className={styles.text}>Copyright &copy; 2022 Mer - v{APP_VERSION} </p>
    <a
      className={styles.support}
      href="Tel: +46700-033970"
    >Ring support
    </a>
  </div>
);

export default Footer;
