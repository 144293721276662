
import React from 'react';
import 'react-table-6/react-table.css';
import { isFolder } from './components/utils'
import * as styles from './FileBrowser.module.scss'
import * as PropTypes from 'prop-types'
import TableHeader from './components/TableHeader/TableHeader'
import TableFile from './components/TableFile/TableFile'
import TableFolder from './components/TableFolder/TableFolder'
import { GroupByFolder } from './components/Groupers/byFolder'


const handleFileClick = async (key:any) => {
  if (window.open(key, '_blank')) {
   window.open(key, '_blank')?.focus()||window.open(key, '_blank')
  }
}

function getItemProps(file:any, browserProps:any) {
  return {
    key: `file-${file.key}`,
    fileKey: file.key,
    documentUrl: file.documentUrl,
    isOpen: file.key in browserProps.openFolders || false,
  }
}
  
 const FileBrowser = (props: any) => 
 {

    const [openFolders, setOpenFolders] = React.useState<any>({})
    const [nameFilter, setNameFilter] = React.useState('')

    const handleShowMoreClick = (event:any) => {
      event.preventDefault()
    }

    const toggleFolder = (folderKey: any) => {
        const isOpen = folderKey in openFolders
        const currentOpenFolders = { ...openFolders }
        if (isOpen) {
          delete currentOpenFolders[folderKey]
        } else {
          currentOpenFolders[folderKey] = true
        }
        setOpenFolders({ ...currentOpenFolders })
      }

      let renderFiles = (files:any, depth:any) => {
        const browserProps = getBrowserProps()
        let renderedFiles: any[]=[];

        files.forEach((file:any) => {
          const thisItemProps = {
            ...browserProps.getItemProps(file, browserProps),
            depth: nameFilter ? 0 : depth,
          }
    
          if (!isFolder(file)) {
            renderedFiles.push(
              <TableFile
                {...file}
                {...thisItemProps}
                handleFileClick={handleFileClick}
              />,
            )
          } else {
            if (!nameFilter) {
              renderedFiles.push(
                <TableFolder
                  {...file}
                  {...thisItemProps}
                  toggleFolder={toggleFolder}
                />,
              )
            }
            if (nameFilter || thisItemProps.isOpen) {
              renderedFiles = renderedFiles.concat(
                renderFiles(file.children, depth + 1),
              )
            }
          }
        })
        return renderedFiles
      }
    
      const getBrowserProps = () => ({
        openFolders,
        nameFilter,
        getItemProps,
      })

  const getFiles = () => {

    let files = props.files?.concat([])||[]
    if (nameFilter) {
      const filteredFiles:any[]=[];
      const terms = nameFilter.toLowerCase().split(' ')
      files.forEach((file:any) => {
        let skip = false
        terms.forEach((term) => {
          if (file.key.toLowerCase().trim().indexOf(term) === -1) {
            skip = true
          }
        })
        if (skip) {
          return
        }
        filteredFiles.push(file)
      })
      files = filteredFiles
    }

    // Group by folder
    files = props.group(files, '')

    return files
  }

  const files = getFiles()

  let contents:any = renderFiles(files, 0)
  if (!contents.length) {
    if (nameFilter) {
      contents = (
        <tr>
          <td colSpan={100}>
            {props.noMatchingFilesMessage(nameFilter)}
          </td>
        </tr>
      )
    } else {
      contents = (
        <tr>
          <td colSpan={100}>{props.noFilesMessage}</td>
        </tr>
      )
    }
 
  } else if (nameFilter) {
    const numFiles = contents.length
    contents = contents.slice(0)
    if (numFiles > contents.length) {
      contents.push(
        <tr key="show-more">
          <td colSpan={100}>
            <a onClick={handleShowMoreClick} href="#">
              {props.showMoreResults}
            </a>
          </td>
        </tr>,
      )
    }
  }
  return (
    <div className={styles.fileBrowser}>
      <div className={styles.tableWrapper}>
        <table cellSpacing="0" cellPadding="0" className={styles.table}>
          <TableHeader />
          <tbody>{contents}</tbody>
        </table>
      </div>

    </div>
  )
 }

FileBrowser.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      documentUrl:PropTypes.string.isRequired
    }),
  ).isRequired,
  noFilesMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  group: PropTypes.func

}

FileBrowser.defaultProps = {
  noFilesMessage: 'No files.',
  group: GroupByFolder
}

export default FileBrowser;



