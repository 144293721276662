export const FuseSizeOptions = [
  10,
  16, 
  20, 
  25, 
  35, 
  50, 
  63, 
  'No Load Balancing'
];
