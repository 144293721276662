import { useMutation, ReactQueryMutationsConfig } from 'react-query';
import { putConfigureAPI } from './api';
import { ConfigureRequest, ConfigureResponse, ErrorType } from './types';

export function useConfigure(
  chargePointId: string,
  bodyData: ConfigureRequest | undefined,
  { onSuccess }: ReactQueryMutationsConfig<ConfigureResponse, ErrorType>
) {
  const [configure, { isLoading, error }] = useMutation<ConfigureResponse, ErrorType, ConfigureRequest>(
    () => putConfigureAPI(chargePointId, bodyData),
    {
      onSuccess
    },
  );
  return {
    configure,
    isLoading,
    error,
  };
}
