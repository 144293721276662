import React, { useState } from 'react';
import { useChargePoint } from 'services/chargepoints/chargepoint/hook';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import { useRollbar } from '@rollbar/react';
import * as Constants from '../../../../assets/constants';
import styles from './styles.module.scss';

const VerifyChargePoint = ({ nextStep, setSerialNum, setChargePointId }: any) => {
  const [serialNumber, setSerialNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const rollbar = useRollbar();

  const { getChargePoint, errorChargePoint } = useChargePoint(serialNumber, () => nextStep());

  const handleSerialNumberInput = (target: any) => {
    if (target.value === '') {
      setErrorMessage(Constants.SERIAL_NUMBER_NOT_EMPTY);
    } else {
      setErrorMessage('');
    }
    setSerialNumber(target.value);
  };

  const verifyChargePoint = async () => {
    setSerialNum(serialNumber);
    
    const chargePoint = await getChargePoint();
    setChargePointId(chargePoint?.chargePointId);

    rollbar.info('verifyChargePoint', { verifyChargePointRequest: serialNumber, verifyChargePointResponse: chargePoint });
  };

  return (
    <div className={styles.verifyChargePoint}>
      <div className={styles.inputSection}>
        <label>{Constants.VERIFY_CHARGE_POINT_LABEL}</label>
        <TextInput
          placeholder={Constants.SERIAL_NUMBER}
          onChange={(target: any) => handleSerialNumberInput(target)}
        />
        <p className={styles.errorMessage}>{errorMessage && errorMessage}</p>
        {errorChargePoint?.statusCode === '404'
          ? <p className={styles.errorMessage}>{Constants.VERIFY_CHARGE_POINT_NOT_FOUND_ERROR}</p>
          : <p className={styles.errorMessage}>{errorChargePoint?.message}</p>}
      </div>
      <div className={styles.button}>
        <Button
          disabled={!!(errorChargePoint || errorMessage || serialNumber === '')}
          onClick={verifyChargePoint}
        >{Constants.CONFIRM}
        </Button>
      </div>
    </div>
  );
};

export default VerifyChargePoint;
