import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Steps from 'rc-steps';
import 'rc-steps/assets/index.css';

import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';
import Container from 'components/layout/Container';
import * as Constants from '../../assets/constants';
import LeftArrow from '../../assets/icons/left-arrow.png';
import OrderInfo from './Components/OrderInfo';
import VerifyChargePoint from './Components/VerifyChargePoint';
import SetFuseSize from './Components/SetFuseSize';
import ConfigureCP from './Components/ConfigureCP';

import styles from './styles.module.scss';
import UploadInstallationImages from './Components/UploadInstallationImages';

const OrderVerificationFlow = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [serialNumber, setSerialNumber] = useState('');
  const [orderId, setOrderId] = useState('');
  const [fuseSize, setFuseSize] = useState('');
  const [chargePointId, setChargePointId] = useState('');
  const [locationImage, setLocationImage] = useState('');
  const [installationImages, setInstallationImages] = useState<File[]>([]);

  const history = useHistory();

  const nextStep = () => {
    let s = currentStep + 1;
    if (s === 5) {
      s = 0;
    }
    setCurrentStep(s);
  };

  const goBack = () => {
    history.push('/order');
  };

  const getFinishIcon = () => (
    <svg 
      width="1em"
      height="1em"
      viewBox="0 -46 417.81333 417" 
      fill="currentColor"
      className={styles.finishIcon}
    >
      <path 
        d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 
            6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 
            0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 
            75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 
            15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"
      />
    </svg>
  );
  
  const icons = {
    finish: getFinishIcon(),
    error: null,
  };

  return (
    <div className={styles.orderVerificationFlow}>
      <Header />
      <Container>
        <div
          className={styles.subNav}
          onClick={goBack}
        >
          <img
            src={LeftArrow}
            className={styles.leftArrowIcon}
            alt="leftArrow"
          />
          <div className={styles.goBackNav}>
            {Constants.RETURN_BACK}
          </div>
        </div>
        <Steps
          initial={0}
          current={currentStep}
          icons={icons}
        >
          <Steps.Step title={Constants.VERIFY_CUSTOMER} />
          <Steps.Step title={Constants.VERIFY_SERIAL_NUMBER} />
          <Steps.Step title={Constants.ENTER_SIZE_OF_MAIN_FUSE} />
          <Steps.Step title={Constants.UPLOAD_INSTALLATION_IMAGES} />
          <Steps.Step title={Constants.CONFIGURE_CP} />
        </Steps>
        <div className={styles.content}>
          {currentStep === 0 && (
          <OrderInfo
            nextStep={nextStep}
            setOrderId={setOrderId}
          />
          )}

          {currentStep === 1 && (
          <VerifyChargePoint
            nextStep={nextStep}
            setSerialNum={setSerialNumber}
            setChargePointId={setChargePointId}
          />
          )}

          {currentStep === 2 && (
          <SetFuseSize
            nextStep={nextStep}
            setFuseSize={setFuseSize}
          />
          )}
          {currentStep === 3 && (
          <UploadInstallationImages
            nextStep={nextStep}
            setLocationImage={setLocationImage}
            setInstallationImages={setInstallationImages}
            fuseSize={fuseSize}
          />
          )}

          {currentStep === 4 && (
          <ConfigureCP
            nextStep={nextStep}
            serialNumber={serialNumber}
            orderId={orderId}
            fuseSize={fuseSize}
            chargePointId={chargePointId}
            locationImage={locationImage}
            installationImages={installationImages}
          />
          ) }
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default OrderVerificationFlow;
