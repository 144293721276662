import { useQuery } from 'react-query';
import { getInstallationManualsAPI } from './api';
import { InstallationManual, ErrorType } from './types';

const USER_QUERY_KEY = 'InstallationManuals';

export const useInstallationManuals = (onSuccess: any) => {
  const { data, isLoading, error, refetch, status } = useQuery<InstallationManual[], ErrorType>(
    [USER_QUERY_KEY],
    () => getInstallationManualsAPI(),
    {
      enabled: false,
      onSuccess,
      cacheTime: 0,
    },
  );

  return {
    getInstallationManuals: refetch,
    installationManuals: data,
    isLoading,
    error,
    status
  };
};
