import { useQuery } from 'react-query';
import { getCustomerAPI } from './api';
import { Customer } from './types';

const USER_QUERY_KEY = 'order';

export const useCustomer = (customerId: string, onSuccess: any) => {
  const { data, isLoading, error, refetch } = useQuery<Customer, Error>(
    [USER_QUERY_KEY, customerId],
    () => getCustomerAPI(customerId),
    {
      enabled: false,
      onSuccess,
    },
  );

  return {
    getCustomer: refetch,
    customer: data,
    isLoading,
    error,
  };
};
