import React from 'react';
import styles from './styles.module.scss';
import { ButtonProps } from './types';

export const Button = ({ type = 'button', children, onClick, disabled=true }: ButtonProps) => (
  <button
    type={type}
    className={disabled? styles.disabledButton : styles.button}
    onClick={() => onClick()}
    disabled={disabled}
  >
    {children}
  </button>
);
