import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'components/layout/Container';
import Header from 'components/layout/Header';
import { Button } from 'components/Button';
import { useOrder } from 'services/orders/order/hook';
import { TextInput } from 'components/TextInput';
import Footer from 'components/layout/Footer';
import { AppContext } from 'store';
import { HOME_CHECK_FLOW, ORDER_VERIFICATION_FLOW } from 'global/routes';
import { OrderResponse, OrderStatus } from 'services/orders/order/types';
import { useRollbar } from '@rollbar/react';
import LeftArrow from '../../assets/icons/left-arrow.png';
import * as Constants from '../../assets/constants';
import styles from './styles.module.scss';

const GetOrderPage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const { setOrderId, orderId, setOrder, setCustomer }: any = useContext(AppContext);

  const rollbar = useRollbar();

  const history = useHistory();

  const { getOrder, error } = useOrder(orderId, (order: OrderResponse) => {
    history.push(order.status !== OrderStatus.Modified ? HOME_CHECK_FLOW : ORDER_VERIFICATION_FLOW, { orderId });
  });

  const handleCheckOrder = async () => {
    const order = await getOrder();
    setOrder(order);
    setCustomer(order?.customer);

    rollbar.info('GetOrder', { getOrderRequest: orderId, getOrderResponse: order });
  };

  const handleTextInput = (target: any) => {
    if (target.value === '') {
      setErrorMessage(Constants.ORDER_NUMBER_NOT_EMPTY);
    } else if (!target.validity.valid) {
      setErrorMessage(Constants.ORDER_NUMBER_ONLY_ALPHA_NUMBERIC);
    } else {
      setErrorMessage('');
    }
    setOrderId(target.value);
  };

  const handleErrorMessage = () => {
    if (error?.statusCode === '404') {
      return Constants.ORDER_NUMBER_NOT_FOUND_ERROR;
    } if (error?.statusCode === '400') {
      if (error.message.startsWith('Order')) {
        return Constants.ORDER_NOT_ACTIVE;
      } if (error.message.startsWith('Customer')) {
        return Constants.CUSTOMER_NOT_FOUND;
      }
    } else {
      return error?.message;
    }
  };

  const goBack = () => {
    history.push('/installation-manuals');
  };

  return (
    <div className={styles.getOrderPage}>
      <Header />
      <Container>
        <div
          className={styles.subNav}
          onClick={goBack}
        >
          <img
            src={LeftArrow}
            className={styles.leftArrowIcon}
            alt="leftArrow"
          />
          <div className={styles.goBackNav}>
            {Constants.RETURN_BACK}
          </div>
        </div>

        <div className={styles.orderId}>
          <h3 className={styles.orderLabel}>{Constants.ORDER_NUMBER_LABEL}</h3>
          <div className={styles.orderInput}>
            <TextInput
              placeholder={Constants.ORDER_NUMBER}
              defaultValue={orderId}
              onChange={(target: any) => handleTextInput(target)}
            />
            {errorMessage
              ? <p className={styles.errorMessage}>{errorMessage}</p>
              : <p className={styles.info}>{Constants.ORDER_NUMBER_INFO}</p>}

            <p className={styles.errorMessage}>{handleErrorMessage()}</p>
          </div>
          <div className={styles.button}>
            <Button
              disabled={!!(errorMessage || orderId === '')}
              onClick={handleCheckOrder}
            >{Constants.CHECK_ORDER}
            </Button>
          </div>
        </div>
            
      </Container>
      <Footer />
    </div>
  );
};

export default GetOrderPage;
