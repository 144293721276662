import { useQuery } from 'react-query';
import { getAddonCostsAPI } from './api';
import { AddonCost, ErrorType } from './types';

const USER_QUERY_KEY = 'AddonCosts';

export const useAddonCosts = (onSuccess: any) => {
  const { data, isLoading, error, refetch, status } = useQuery<AddonCost[], ErrorType>(
    [USER_QUERY_KEY],
    () => getAddonCostsAPI(),
    {
      enabled: false,
      onSuccess,
      cacheTime: 0,
    },
  );

  return {
    getAddonCosts: refetch,
    addonCosts: data,
    isLoading,
    error,
    status
  };
};
