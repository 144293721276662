import { useQuery } from 'react-query';
import { getChargePointAPI } from './api';
import { ChargePoint, ErrorType } from './types';

const USER_QUERY_KEY = 'ChargePoint';

export const useChargePoint = (serialNumber: string, onSuccess: any) => {
  const { data, isLoading, error, refetch } = useQuery<ChargePoint, ErrorType>(
    [USER_QUERY_KEY, serialNumber],
    () => getChargePointAPI(serialNumber),
    {
      enabled: false,
      onSuccess,
      cacheTime: 0,
    },
  );

  return {
    getChargePoint: refetch,
    chargePoint: data,
    isLoading,
    errorChargePoint: error,
  };
};
