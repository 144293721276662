import { Button } from 'components/Button';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'react-spinners/BeatLoader';
import { useRollbar } from '@rollbar/react';
import { useInstallationManuals } from 'services/installationManuals/hook';
import { InstallationManual } from 'services/installationManuals/types';
import { GET_ORDER } from 'global/routes';
import * as Constants from '../../assets/constants';
import 'react-table-6/react-table.css';
import styles from './styles.module.scss';
import FileBrowser from '../FileBrowser/FileBrowser';

const InstallationManualsPage = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>({files:[{
    key: "", 
    documentUrl:""
}]});
  const rollbar = useRollbar();
  const history = useHistory();

  const { getInstallationManuals } = useInstallationManuals((installationManuals: InstallationManual[]) => {
    
    const listOfFiles: { key: string, documentUrl:string}[] = [];
    installationManuals.forEach(item=>{
      let files = {
        key: item.title,
        documentUrl:item.documentUrl
      };
      listOfFiles.push(files);
    })

    data.files=listOfFiles

    rollbar.info('getInstallationManuals');
  });

  useEffect(() => {
    const fetchData = async () => {
      await getInstallationManuals();

      setLoading(false);
    };

    setTimeout(() => {
      fetchData();
    }, 500);
  }, [getInstallationManuals]);
  
  const renderLoader = () => (
    <div className={styles.loader}> 
      <Loader
        color="#000000"
        loading={loading}
        size={20}
        margin={10}
      />
    </div>
  );

  const filterAddonCosts = () => {
    history.push(GET_ORDER);
  };

  return (
    <div className={styles.installationManualsPage}>
      <h2 className={styles.installationManualsTitle}>
        {Constants.INSTALLATION_MANUALS}
      </h2>

      <div>{renderLoader()}</div>
      <FileBrowser  files={data?.files||'' } />

      <div className={styles.button}>
        <Button
          disabled={loading}
          onClick={filterAddonCosts}
        >{Constants.CONFIRM}
        </Button>
      </div>
        
    </div>
  );
};

export default InstallationManualsPage