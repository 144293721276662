import { UPLOAD_FILE } from 'assets/constants';
import { Button } from 'components/Button';
import React, { useRef, useState } from 'react';
import { compressImageFile } from 'utils/compressImageFile';
import closeIcon from '../../assets/icons/close-icon.png';
import styles from './styles.module.scss';

export const FileUpload = ({ setFile, className }: any) => {
  const [selectedFile, setSelectedFile] = useState<File|undefined>();
  const [isSelected, setIsSelected] = useState(false);
  const [fileUrl, setFileUrl] = useState<string|undefined>('');
  
  const hiddenFileInput = useRef(null);
  
  const handleChange = async (event: any) => {
    const uploadedFile = event.target.files[0];

    const compressedFile = await compressImageFile(uploadedFile);
    
    setFile(compressedFile);
    setSelectedFile(compressedFile);
    setFileUrl(URL.createObjectURL(compressedFile));
    setIsSelected(true);
  };

  const handleClose = () => {
    setFile(undefined);
    setSelectedFile(undefined);
    setFileUrl(undefined);
    setIsSelected(false);
  };

  return (
    <div className={className}>
      {isSelected
        ? (
          <div>
            <div className={styles.filePreviewer}>
              <div> 
                <span>
                  <a
                    href={fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.filePreview}
                  >
                    <img
                      className={styles.thumbnail}
                      src={fileUrl}
                      alt={(selectedFile as any).name}
                    />
                  </a>   
                  <a
                    href={fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.fileUrl} ${styles.fileName}`}
                  >
                    {(selectedFile as any).name}
                  </a>                  
                </span>
              </div>
              <img
                className={styles.closeIcon}
                src={closeIcon}
                alt="close-icon"
                onClick={handleClose}
              />
            </div>
          </div>
        ) 
        : (
          <div>
            <input
              type="file"
              accept="image/png, image/jpeg"
              name="file"
              onChange={handleChange}
              ref={hiddenFileInput}
              className={styles.fileInput}
              placeholder="Upload file"
            />
            <Button
              onClick={() => (hiddenFileInput as any).current.click()}
              disabled={false}
            >
              <svg
                viewBox="0 0 900 800"
                width="1em"
                height="1em"
                fill="currentColor"
                className={styles.uploadIcon}
              >
                <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 
                  0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 0 0-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 
                  13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 
                  8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z" 
                />
              </svg>
              
              {UPLOAD_FILE}
            </Button>
          </div>
        )}
      
    </div>
  );
};
