import React, { useContext } from 'react';
import { useCustomer } from 'services/customers/customer/hook';
import { Button } from 'components/Button';
import { AppContext } from 'store';
import Loader from 'components/Loader';
import { useRollbar } from '@rollbar/react';
import { usePosition } from '../../../../utils/usePosition';
import * as Constants from '../../../../assets/constants';
import styles from './styles.module.scss';

const OrderInfo = ({ nextStep, setOrderId }: any) => {
  const { setCustomerId, setPosition, order }: any = useContext(AppContext);  
  const { latitude, longitude, errorPosition } = usePosition();
  const rollbar = useRollbar();

  const { getCustomer, error } = useCustomer(order?.customer?.customerId || '', () => nextStep());
  
  const verifyCustomer = () => {
    setPosition({ lat: latitude, long: longitude });
    setCustomerId(order?.customer?.customerId);
    setOrderId(order?.orderId);
    getCustomer();
    
    rollbar.info('verifyCustomer');
  };

  return (
    <div className={styles.orderInfo}>
      <p><strong>{Constants.ORDER_ID}</strong> {order?.orderId}</p>
      <p><strong>{Constants.CUSTOMER_NAME}</strong> {order?.customer.customerName}</p>
      <p><strong>{Constants.CUSTOMER_NUMBER}</strong> {order?.customer?.customerId}</p>
      <p><strong>{Constants.CUSTOMER_ADRESS}</strong> {order?.location?.streetName}</p>
      <p><strong>{Constants.POSTAL_CODE}</strong> {order?.location?.postalCode}</p>
      <p><strong>{Constants.CITY}</strong> {order?.location?.city}</p>
      <p><strong>{Constants.COUNTRY}</strong> {order?.location?.country}</p>
      <p><strong>{Constants.LAT}</strong> {latitude}</p>
      <p><strong>{Constants.LONG}</strong> {longitude}</p>
      {errorPosition && <p className={styles.errorPosition}>{errorPosition}</p> }
      {(!latitude && !longitude) && !errorPosition && (
        <Loader
          loading
          message={Constants.FIND_LOCATION}
        />
      )}
      <div className={styles.button}>
        <Button
          disabled={false}
          onClick={verifyCustomer}
        >{Constants.CONFIRM}
        </Button>
      </div>
      <p className={styles.errorMessage}>{error && error}</p>
    </div>  
  );
};

export default OrderInfo;
