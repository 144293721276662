import { isFolder } from '../utils'

export const GroupByFolder=(files:any, root:any)=> {
  let fnFiles = files
  const fileTree = {
    contents: [],
    children: {},
  }

  fnFiles.forEach((file:any) => {
    // eslint-disable-next-line no-param-reassign
    file.relativeKey = (file.newKey || file.key).substr(root.length)
    let currentFolder :any = fileTree
    const folders = file.relativeKey.split('/')
    folders.forEach((folder:any, folderIndex:any) => {
      if (folderIndex === folders.length - 1 && isFolder(file)) {
        Object.keys(file).forEach((key) => {
          currentFolder[key] = file[key]
        })
      }
      if (folder === '') {
        return
      }
      const isAFile = (!isFolder(file) && (folderIndex === folders.length - 1))
      if (isAFile) {
        currentFolder.contents.push({
          ...file,
          keyDerived: true,
        })
      } else {
        if (folder in currentFolder.children === false) {
          currentFolder.children[folder] = {
            contents: [],
            children: {},
          }
        }
        currentFolder = currentFolder.children[folder]
      }
    })
  })

  function addAllChildren(level:any, prefix:any) {
    let fnPrefix = prefix
    if (fnPrefix !== '') {
      fnPrefix += '/'
    }

    let childrenFiles :any[]=[]

    Object.keys(level.children).forEach((folder) => {
      childrenFiles.push({
        ...level.children[folder],
        contents: undefined,
        keyDerived: true,
        key: `${root + fnPrefix + folder}/`,
        relativeKey: `${fnPrefix + folder}/`,
        children: addAllChildren(level.children[folder], fnPrefix + folder),
        size: 0,
      })
    })
    childrenFiles = childrenFiles.concat(level.contents)
    
    return childrenFiles
  }

  fnFiles = addAllChildren(fileTree, '')
  return fnFiles
}
