import React, { useState } from 'react';
import { Button } from 'components/Button';
import { SelectInput } from 'components/SelectInput';
import { arrayToSelectInputOptions } from 'utils/ArrayHelpers';
import * as Constants from '../../../../assets/constants';
import styles from './styles.module.scss';
import { FuseSizeOptions } from './types';

const SetFuseSize = ({ nextStep, setFuseSize }: any) => {
  const [selectedFuseSize, setSelectedFuseSize] = useState('');

  const fuseSizeOptions = arrayToSelectInputOptions(FuseSizeOptions);

  const handleFuseSizeChange = (selectedFuseSize: string) => {
    setSelectedFuseSize(selectedFuseSize);
  };

  const confirmFuseSize = () => {   
    setFuseSize(selectedFuseSize);
    nextStep();
  };

  return (
    <div className={styles.setFuseSize}>
      <div className={styles.selectSection}>
        <label>{Constants.ENTER_SIZE_OF_MAIN_FUSE}</label>
        <SelectInput
          value={selectedFuseSize}
          data={fuseSizeOptions}
          handleChange={(event: any) => handleFuseSizeChange(event)}
          placeholder={Constants.SELECT_FUSE_SIZE}
        />
      </div>
      <div className={styles.button}>
        <Button
          disabled={!!(selectedFuseSize === '')}
          onClick={confirmFuseSize}
        >{Constants.CONFIRM}
        </Button>
      </div>
    </div>
  );
};

export default SetFuseSize;
