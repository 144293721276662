import { Button } from 'components/Button';
import React from 'react';
import ReactTable from 'react-table-6';
import * as Constants from '../../../../assets/constants';
import 'react-table-6/react-table.css';
import styles from './styles.module.scss';

const VerifyAddonCosts = ({ nextStep, filteredAddonCosts }: any) => {

  const columns = [
    { accessor: 'description', Header: Constants.ADDON_COST_DESCRIPTION, className: styles.columnStyle, width: 500 },
    { accessor: 'quantity', Header: Constants.ADDON_COST_QUANTITY, className: styles.columnStyle, width: 100 },
    { accessor: 'priceExclVAT', Header: Constants.ADDON_COST_PRICE_EXCL_VAT, className: styles.columnStyle, width: 150 },
    { accessor: 'priceInclVAT', Header: Constants.ADDON_COST_PRICE_INCL_VAT, className: styles.columnStyle, width: 150 },
    { accessor: 'amount', Header: Constants.ADDON_COST_AMOUNT, className: styles.columnStyle, width: 200 }
  ];

  const confirmAddonCosts = () => {
    nextStep();
  };

  return (
    <div className={styles.verifyAddonCosts}>
        
      <ReactTable
        data={filteredAddonCosts}
        columns={columns}
        showPagination={false}
        pageSize={filteredAddonCosts.length}
        className="-striped"
        noDataText={Constants.ADDON_COSTS_NOT_SELECTED}
      />

      <div className={styles.button}>
        <Button
          disabled={false}
          onClick={confirmAddonCosts}
        >{Constants.CONFIRM}
        </Button>
      </div>

    </div>
  );
};

export default VerifyAddonCosts;
